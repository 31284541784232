<template>
  <section class="modal--container auth--container">
    <div class="title--text hide--in-mobile">
      {{ $t('loginRegister.login') }}
    </div>
    <SectionLoader :show="isLoading" />
    <!--    <social-media-auth @updateLoading="updateLoading" />-->
    <!--    <hr class="separator" />-->
    <b-alert :variant="alertType" :show="showAlert">
      <div v-html="messageAlert"></div>
      <resend-activation v-if="unverifiedEmail" :email="email" />
    </b-alert>
    <form @submit.prevent="login" id="loginForm">
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('email') }">
        <v-text-field
          :label="$t('loginRegister.email')"
          outlined
          :hide-details="true"
          v-model="email"
          name="email"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('password') }">
        <v-text-field
          :label="$t('loginRegister.pass')"
          outlined
          :hide-details="true"
          v-model="password"
          name="password"
          class="basic--input"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showPass = !showPass)"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('password')">{{
          validation.firstError('password')
        }}</span>
      </div>
      <a href="javascript:;" @click="showModal('modal-forget-pass')" class="auth--link">{{
        $t('loginRegister.forgot')
      }}</a>
      <hr class="separator" />
      <div v-if="isConversion">
        <div class="agent--conversion mb-4">
          {{ $t('loginRegister.agentConvert') }}
        </div>
        <!--        <div class="auth&#45;&#45;input mb-3" :class="{ 'has-error': validation.hasError('referralCode') }">-->
        <!--          <v-text-field-->
        <!--            :label="$t('loginRegister.referralCode')"-->
        <!--            outlined-->
        <!--            :hide-details="true"-->
        <!--            v-model="referralCode"-->
        <!--            name="referral_code"-->
        <!--            class="basic&#45;&#45;input"-->
        <!--          ></v-text-field>-->
        <!--          <span class="val-error" v-if="validation.hasError('referralCode')">{{-->
        <!--            validation.firstError('referralCode')-->
        <!--          }}</span>-->
        <!--        </div>-->
        <div
          class="auth--input mb-3"
          :class="{ 'has-error': validation.hasError('selectedAgentType') }"
        >
          <multiselect
            name="agentType"
            id="agentType"
            v-model="selectedAgentType"
            :placeholder="$t('loginRegister.agentType')"
            :options="agentTypes"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
            select-label=""
            deselect-label=""
          ></multiselect>
          <span class="val-error" v-if="validation.hasError('selectedAgentType')">{{
            validation.firstError('selectedAgentType')
          }}</span>
        </div>
        <div
          class="auth--input mb-3"
          :class="{ 'has-error': validation.hasError('selectedAgentOffice') }"
          v-show="selectedAgentType && selectedAgentType.id === 2"
        >
          <multiselect
            name="agentOffice"
            id="agentOffice"
            v-model="selectedAgentOffice"
            :placeholder="$t('loginRegister.agentOffice')"
            :options="agentOffices"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
            select-label=""
            deselect-label=""
          >
            <template slot="option" slot-scope="{ option }">
              <div class="agent--ofice-option">
                <div class="option--img" v-if="option.logo_url">
                  <img :src="option.logo_url" :alt="option.name" />
                </div>
                <div class="option--text">
                  {{ option.name }}
                </div>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <div class="agent--ofice-option">
                <div class="option--img" v-if="option.logo_url">
                  <img :src="option.logo_url" :alt="option.name" />
                </div>
                <div class="option--text">
                  {{ option.name }}
                </div>
              </div>
            </template>
          </multiselect>
          <span class="val-error" v-if="validation.hasError('selectedAgentOffice')">{{
            validation.firstError('selectedAgentOffice')
          }}</span>
          <div
            class="custom-control custom-checkbox my-2"
            style="width: fit-content; margin-left: 16px"
          >
            <input
              @click="noAgentOffice = !noAgentOffice"
              type="checkbox"
              class="custom-control-input"
              :checked="noAgentOffice"
              id="no-agent-office"
            />
            <label class="custom-control-label" for="no-agent-office">
              {{ $t('loginRegister.noAgentOffice') }}
            </label>
          </div>
        </div>
        <div
          class="auth--input mb-3"
          :class="{ 'has-error': validation.hasError('selectedAreaSpec') }"
        >
          <multiselect
            name="areaSpec"
            id="areaSpec"
            v-model="selectedAreaSpec"
            :placeholder="$t('profile.areaSpec')"
            :options="areaSpecList"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
            :multiple="true"
            select-label=""
            deselect-label=""
          ></multiselect>
          <span class="val-error" v-if="validation.hasError('selectedAreaSpec')">{{
            validation.firstError('selectedAreaSpec')
          }}</span>
        </div>
      </div>
      <button :disabled="isLoading" type="submit" class="btn btn-primary main--btn">Login</button>
    </form>
    <div class="bottom--part">
      <span class="mr-2">{{ $t('loginRegister.message1') }}</span>
      <a href="javascript:" @click="showModal('modal-register')" class="auth--link">{{
        $t('loginRegister.messageBold1')
      }}</a>
    </div>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
// const SocialMediaAuth = () => import('@/components/auth/social-media-auth');
const ResendActivation = () => import('@/components/auth/resend-activation');
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  mixins: [HelperMixin],
  data() {
    return {
      email: null,
      password: null,
      isLoading: false,
      remember: false,
      messageAlert: null,
      showAlert: false,
      alertType: '',
      unverifiedEmail: false,
      showPass: false,
      needOtp: false,
      isConversion: false,
      referralCode: null,
      selectedAgentType: null,
      selectedAgentOffice: null,
      noAgentOffice: false,
    };
  },
  props: ['formType', 'dismissable'],
  components: {
    Multiselect,
    // SocialMediaAuth,
    ResendActivation,
    SectionLoader,
  },
  computed: {
    ...mapState({
      agentTypes: (state) => state.auth.agentTypes,
      areaSpecList: (state) => state.auth.areaSpecList,
      agentOffices: (state) => state.auth.agentOffices,
    }),
    selectedAreaSpec: {
      get() {
        return this.$store.state.auth.selectedAreaSpec;
      },
      set(value) {
        this.$store.commit('auth/SET_SELECTED_AREA_SPEC', value);
      },
    },
  },
  validators: {
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }));
    },
    password(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.password.required'))
        .minLength(6, this.$i18n.t('errors.loginRegister.password.min', { minChar: 6 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
    },
    // referralCode(value) {
    //   return Validator.value(value);
    // },
    selectedAgentType(value) {
      if (this.isConversion) {
        return Validator.value(value).required(
          this.$i18n.t('errors.loginRegister.selectedAgentType.required'),
        );
      } else {
        return Validator.value(value);
      }
    },
    selectedAgentOffice(value) {
      if (
        this.isConversion &&
        !this.noAgentOffice &&
        this.selectedAgentType &&
        this.selectedAgentType.id === 2
      ) {
        return Validator.value(value).required(
          this.$i18n.t('errors.loginRegister.selectedAgentOffice.required'),
        );
      } else {
        return Validator.value(value);
      }
    },
    selectedAreaSpec(value) {
      if (this.isConversion) {
        return Validator.value(value).required(this.$i18n.t('errors.selectedAreaSpec.required'));
      } else {
        return Validator.value(value);
      }
    },
  },
  watch: {
    selectedAgentOffice(val) {
      if (val !== null) {
        this.noAgentOffice = false;
      }
      this.$validate();
    },
    noAgentOffice(val) {
      if (val === true) {
        this.selectedAgentOffice = null;
      }
      this.$validate();
    },
  },
  methods: {
    async showModal(modalName) {
      this.$modal.hide('modal-login');
      if (modalName === 'modal-forget-pass') {
        this.$modal.show(modalName);
      } else {
        if (this.formType === 'page') {
          this.$router.push('/register');
        } else {
          this.$modal.show(modalName, { dismissable: this.dismissable });
        }
      }
    },
    updateLoading(val) {
      this.isLoading = val;
    },
    async login() {
      try {
        this.hasError = false;
        this.dataErrorMessage = null;
        this.dataErrorCode = null;
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          this.isLoading = true;
          if (!this.isConversion) {
            await this.$store
              .dispatch('global/login', {
                fields: {
                  email: this.cleanInput(this.email),
                  password: this.cleanInput(this.password),
                  remember: this.remember,
                },
              })
              .catch((e) => {
                this.hasError = true;
                this.dataErrorMessage = e.response.data.errors;
                this.dataErrorCode = e.response.data.code;
              });
          } else {
            await this.$store
              .dispatch('global/convertToAgent', {
                fields: {
                  email: this.cleanInput(this.email),
                  password: this.cleanInput(this.password),
                  referral_code: this.cleanInput(this.referralCode),
                  agent_type_id: this.selectedAgentType ? this.selectedAgentType.id : null,
                  area_specialization_ids: this.selectedAreaSpec.map((e) => e.id),
                  agent_office_id:
                    !this.noAgentOffice &&
                    this.selectedAgentType &&
                    this.selectedAgentType.id === 2 &&
                    this.selectedAgentOffice
                      ? this.selectedAgentOffice.id
                      : null,
                },
              })
              .catch((e) => {
                this.hasError = true;
                this.dataErrorMessage = e.response.data.errors;
                this.dataErrorCode = e.response.data.code;
              });
          }
          if (!this.hasError) {
            this.$router.replace('/');
            // this.isLoading = false;
          } else {
            this.showAlert = false;
            if (this.dataErrorCode === 602) {
              this.showAlert = true;
              this.alertType = 'danger';
              this.unverifiedEmail = true;
              this.messageAlert = this.dataErrorMessage;
            }
            if (this.dataErrorCode === 601 || this.dataErrorCode === 605) {
              this.showAlert = true;
              this.alertType = 'danger';
              this.unverifiedEmail = false;
              this.messageAlert = this.dataErrorMessage;
            }
            if (this.dataErrorCode === 604) {
              await this.changeToConversion();
            }
            this.loading = false;
          }
        } else {
          this.scrollToErrorSection(this.$el);
        }

        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
    async changeToConversion() {
      this.isConversion = true;
      this.selectedAreaSpec = [];
      await this.$store.dispatch('auth/getRegisterForm');
      // if (this.agentTypes && this.agentTypes.length > 0) {
      //   this.selectedAgentType = this.agentTypes[0];
      // }
    },
  },
  mounted() {
    // this.openModal();
  },
};
</script>
